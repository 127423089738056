const SampleData = [
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/8/2020",
    total_cases: "1754914",
    total_deaths: "62130",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/9/2020",
    total_cases: "1771548",
    total_deaths: "62663",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/10/2020",
    total_cases: "1792614",
    total_deaths: "63179",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/11/2020",
    total_cases: "1814398",
    total_deaths: "63603",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/12/2020",
    total_cases: "1835952",
    total_deaths: "64123",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/13/2020",
    total_cases: "1854493",
    total_deaths: "64267",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/14/2020",
    total_cases: "1874870",
    total_deaths: "64500",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/15/2020",
    total_cases: "1893439",
    total_deaths: "65006",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/16/2020",
    total_cases: "1918739",
    total_deaths: "65618",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/17/2020",
    total_cases: "1954271",
    total_deaths: "66150",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/18/2020",
    total_cases: "1982831",
    total_deaths: "66640",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/19/2020",
    total_cases: "2010080",
    total_deaths: "67177",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/20/2020",
    total_cases: "2046164",
    total_deaths: "67503",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/21/2020",
    total_cases: "2079681",
    total_deaths: "67718",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/22/2020",
    total_cases: "2116612",
    total_deaths: "68409",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/23/2020",
    total_cases: "2155999",
    total_deaths: "69157",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/24/2020",
    total_cases: "2195148",
    total_deaths: "69732",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/25/2020",
    total_cases: "2227951",
    total_deaths: "70302",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/26/2020",
    total_cases: "2262739",
    total_deaths: "70513",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/27/2020",
    total_cases: "2295232",
    total_deaths: "70860",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/28/2020",
    total_cases: "2336692",
    total_deaths: "71217",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/29/2020",
    total_cases: "2389967",
    total_deaths: "71675",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/30/2020",
    total_cases: "2440206",
    total_deaths: "72657",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "12/31/2020",
    total_cases: "2496235",
    total_deaths: "73622",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "1/1/2021",
    total_cases: "2549693",
    total_deaths: "74237",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "1/2/2021",
    total_cases: "2607546",
    total_deaths: "74682",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "1/3/2021",
    total_cases: "2662703",
    total_deaths: "75137",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "1/4/2021",
    total_cases: "2721626",
    total_deaths: "75547",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "1/5/2021",
    total_cases: "2782713",
    total_deaths: "76428",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "1/6/2021",
    total_cases: "2845269",
    total_deaths: "77470",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "1/7/2021",
    total_cases: "2898056",
    total_deaths: "78632",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "1/8/2021",
    total_cases: "2966248",
    total_deaths: "79965",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "1/9/2021",
    total_cases: "3026346",
    total_deaths: "81000",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "1/10/2021",
    total_cases: "3081372",
    total_deaths: "81567",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "1/11/2021",
    total_cases: "3127647",
    total_deaths: "82096",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "1/12/2021",
    total_cases: "3173295",
    total_deaths: "83342",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "1/13/2021",
    total_cases: "3220957",
    total_deaths: "84910",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "1/14/2021",
    total_cases: "3269761",
    total_deaths: "86163",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "1/15/2021",
    total_cases: "3325646",
    total_deaths: "87448",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "1/16/2021",
    total_cases: "3367074",
    total_deaths: "88747",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "1/17/2021",
    total_cases: "3405744",
    total_deaths: "89429",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "1/18/2021",
    total_cases: "3443354",
    total_deaths: "90031",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "1/19/2021",
    total_cases: "3476808",
    total_deaths: "91643",
  },
  {
    iso_code: "GBR",
    continent: "Europe",
    location: "United Kingdom",
    date: "1/20/2021",
    total_cases: "3515800",
    total_deaths: "93469",
  },
  {
    iso_code: "IND",
    continent: "Asia",
    location: "India",
    date: "11/1/2020",
    total_cases: "8229313",
    total_deaths: "122607",
  },
  {
    iso_code: "IND",
    continent: "Asia",
    location: "India",
    date: "11/3/2020",
    total_cases: "8313876",
    total_deaths: "123611",
  },
  {
    iso_code: "IND",
    continent: "Asia",
    location: "India",
    date: "11/6/2020",
    total_cases: "8462080",
    total_deaths: "125562",
  },
  {
    iso_code: "IND",
    continent: "Asia",
    location: "India",
    date: "11/8/2020",
    total_cases: "8553657",
    total_deaths: "126611",
  },
  {
    iso_code: "DEU",
    continent: "Europe",
    location: "Germany",
    date: "12/16/2020",
    total_cases: "1423830",
    total_deaths: "24273",
  },
  {
    iso_code: "DEU",
    continent: "Europe",
    location: "Germany",
    date: "12/17/2020",
    total_cases: "1454009",
    total_deaths: "25027",
  },
  {
    iso_code: "DEU",
    continent: "Europe",
    location: "Germany",
    date: "12/18/2020",
    total_cases: "1486839",
    total_deaths: "25754",
  },
  {
    iso_code: "DEU",
    continent: "Europe",
    location: "Germany",
    date: "12/19/2020",
    total_cases: "1508518",
    total_deaths: "26171",
  },
  {
    iso_code: "DEU",
    continent: "Europe",
    location: "Germany",
    date: "12/20/2020",
    total_cases: "1514962",
    total_deaths: "26400",
  },
  {
    iso_code: "DEU",
    continent: "Europe",
    location: "Germany",
    date: "12/21/2020",
    total_cases: "1534218",
    total_deaths: "27110",
  },
  {
    iso_code: "DEU",
    continent: "Europe",
    location: "Germany",
    date: "12/22/2020",
    total_cases: "1570371",
    total_deaths: "28096",
  },
  {
    iso_code: "DEU",
    continent: "Europe",
    location: "Germany",
    date: "12/23/2020",
    total_cases: "1604129",
    total_deaths: "28909",
  },
  {
    iso_code: "DEU",
    continent: "Europe",
    location: "Germany",
    date: "12/24/2020",
    total_cases: "1630596",
    total_deaths: "29330",
  },
  {
    iso_code: "DEU",
    continent: "Europe",
    location: "Germany",
    date: "12/25/2020",
    total_cases: "1632736",
    total_deaths: "29580",
  },
  {
    iso_code: "DEU",
    continent: "Europe",
    location: "Germany",
    date: "12/26/2020",
    total_cases: "1646240",
    total_deaths: "29946",
  },
  {
    iso_code: "DEU",
    continent: "Europe",
    location: "Germany",
    date: "12/27/2020",
    total_cases: "1658639",
    total_deaths: "30297",
  },
  {
    iso_code: "DEU",
    continent: "Europe",
    location: "Germany",
    date: "12/28/2020",
    total_cases: "1672643",
    total_deaths: "31145",
  },
  {
    iso_code: "DEU",
    continent: "Europe",
    location: "Germany",
    date: "12/29/2020",
    total_cases: "1692109",
    total_deaths: "32267",
  },
  {
    iso_code: "DEU",
    continent: "Europe",
    location: "Germany",
    date: "12/30/2020",
    total_cases: "1741153",
    total_deaths: "33230",
  },
  {
    iso_code: "DEU",
    continent: "Europe",
    location: "Germany",
    date: "12/31/2020",
    total_cases: "1760520",
    total_deaths: "33791",
  },
];

export default SampleData;
